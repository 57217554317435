import {useState, useEffect} from 'react';
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {HomeAssistant} from '@homefile/components';
import { setDrawer } from "../../../features/drawer/drawerSlice";
import {setPanel, setStep} from "../../../features/assistant/assistantSlice"
import {useHomeAssistant} from "../../../hooks/useHomeAssistant"

export const HomeAssistantModule = () => {
    const dispatch = useAppDispatch()
    const currentPanel = useAppSelector((state: RootState) => state.assistant.currentPanel)
    const {completeNum} = useHomeAssistant()
    const [completedStep, updateCompletedStep] = useState(1)

    const handleStepClick = (step: number) => {
        dispatch(setPanel({currentPanel: step}))
        dispatch(setStep({currentStep: step}))
        dispatch(setDrawer({ location: "right", content:'HomeAssistant', size: "md"}))
    }

    useEffect(() => {
        if (completeNum === 0) {
            updateCompletedStep(1)
        } else if (completeNum < 6) {
            updateCompletedStep(completeNum)
        } else {
            updateCompletedStep(7)
        }
    }, [completeNum])

    return (
        <HomeAssistant 
            showTutorial={true}
            currentStep={completedStep}
            onStepClick={handleStepClick}
            currentPanel={currentPanel}
            tutorialUrl={""}
        />
    )
}

