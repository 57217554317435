import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  useGetHomeQuery,
  useGetProgressReportsQuery
} from "../../../features/api";
import { RootState } from "../../../app/store";
import { setRecipients } from '../../../features/document/documentSlice';
import { ListInterface } from "../../../interfaces/UserInterfaces";
import { 
  ShareHomeConnections,
  ShareHomeForm,
  SharedHomeHeader,
  useCustomToast,
  TabsHeader
} from "@homefile/components";
import { AssociatedAccountI } from "@homefile/components/dist/interfaces/shareHome/ShareHome.interface";
import { useAssociatedAccount } from "../../../hooks/useAssociatedAccount";
import DeleteAssociationDialog from "../../Dialogs/DeleteAssociationDialog";
import { Box, DrawerBody } from "@chakra-ui/react";
import { useGetDefaultConfig } from "../../../hooks/useGetDefaultConfig";
import {UserI} from '@homefile/components/dist/interfaces'
import { useUpdateItem } from "../../../hooks/useUpdateItem";
import RolePermissionsModule from "../../Modules/RolePermissionsModule";

const ClientsListModule = ({ featuredHome, homeDrawer, handleClose }: ListInterface) => {
  let homeId = useAppSelector((state: RootState) => state.home.id);
  const [clients, updateClients] = useState([] as AssociatedAccountI[])
  const userEmail = useAppSelector((state: RootState) => state.auth.user.email);
  const userType = useAppSelector((state: RootState) => state.auth.homeAssociationType)
  const [disabled, updateDisabled] = useState(false)
  const dispatch = useAppDispatch()
  if (featuredHome) {
    homeId = featuredHome;
  }
  const [contacts, updateContacts] = useState<UserI[]>([])
  const {defaultConfig} = useGetDefaultConfig()
  const [skip, updateSkip] = useState(true)
  const { data: contactData } = useGetProgressReportsQuery({homeId, type: defaultConfig.config.contactTypeId, subType: ""}, {skip});
  const { data } = useGetHomeQuery(homeId);
  const toast = useCustomToast()
  const successFunction = () => {

    toast({
        title: 'Contact Updated!',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: "top-right"
      })
  }
  const {handleUpdate} = useUpdateItem(successFunction);
  const {loading, handleAdd, handleCloseDeleteAccountModal, handleRemoveAccount, openRemoveDialog, deleteAccountModal, associationId, handleUpdateAccount} = useAssociatedAccount(clients)

  useEffect(() => {
    updateDisabled(userType === "member" ? true : false)
  }, [userType])

  useEffect(() => {
    if (data) {
      let recipientState = [] as AssociatedAccountI[];
        data.associations.filter((account: AssociatedAccountI) => account.user.email !== userEmail).forEach((account: AssociatedAccountI) => {
          if (account.user) {
            recipientState.push(account)
          }
        });
        dispatch(setRecipients({recipients: recipientState}))
        updateClients(recipientState)
    }
  }, [data]);

  useEffect(() => {
    if (defaultConfig.config.contactTypeId !== "") {
        updateSkip(false)
    }
}, [defaultConfig])

  useEffect(() => {
    if (contactData && contactData.length > 0) {
      let contactList: UserI[] = []
            contactData.forEach((contact: any) => {
                if (contact.report.length > 0) {
                    const reportObj: any = {}
                    contact.report.forEach((field: any) => {
                        if (field.type === "grid") {
                            field.children.forEach((child: any) => {
                                const keyValue = child.name.replace(/\s+/g, '').toLowerCase()
                                reportObj[keyValue] = child.value
                            })
                        } else {
                            const keyValue = field.name.replace(/\s+/g, '').toLowerCase()
                            reportObj[keyValue] = field.value
                        }
                    })
                    let newContact = {
                      email: reportObj.email,
                      firstName: reportObj.firstname,
                      _id: contact._id,
                      lastName: reportObj.lastname,
                      phone: reportObj.phone,
                    }
                    if (reportObj.firstname.length === 0 && reportObj.lastname.length === 0) {
                      newContact = {...newContact, firstName: "Missing", lastName: "Name"}
                    } 
                    contactList = [...contactList, newContact]
                } else {
                    const newContact = {
                        email: "",
                        firstName: "",
                        _id: contact._id,
                        lastName: "",
                        phone: "",
                    }
                    contactList = [...contactList, newContact]
                }
            })
            updateContacts(contactList)
    }
  }, [contactData])

  const connectionsMenu = [
    {
      label: 'Unshare Home',
      handleClick: (email: string) => {openRemoveDialog(email)},
    },
  ]
  
  const onEmailSave = (form: UserI) => {

    const selectedContact = contactData.find((contact: any) => contact._id === form._id)

    if (selectedContact) {
      let report = [...selectedContact.report]

      for (let i = 0; i < report.length; i++) {
        if (report[i].name === "Email") {
          const field = {...report[i], value: form.email}
          report[i] = field
          break;
        }
      }

      handleUpdate({
        newReportId: selectedContact._id,
        homeId,
        reportTitle: selectedContact.title,
        report: report,
      })
    }

  }

  const tabList = [
    {
      label: "Shared Accounts",
      component: <ShareHomeConnections 
        recipients={clients}
        menu={connectionsMenu}
        onEdit={handleUpdateAccount}
      />
    },
    {
      label: "Role Permissions",
      component: <RolePermissionsModule />,
    }
  ]

  return (
    <>
      {
        homeDrawer ? (
          <Box p="0">
            {
              !disabled && <ShareHomeForm 
                onAdd={handleAdd}
                loading={loading}
                contacts={contacts}
                onEmailSave={onEmailSave}
              />
            }
            <ShareHomeConnections 
              recipients={clients}
              menu={connectionsMenu}
              onEdit={handleUpdateAccount}
            />
          </Box>
        ) : (
          <>
            <SharedHomeHeader onClose={handleClose ? handleClose : () => {}}>
            {
              !disabled && <Box bg="lightBlue.1">
                <ShareHomeForm 
                  onAdd={handleAdd}
                  loading={loading}
                  contacts={contacts}
                  onEmailSave={onEmailSave}
                />
              </Box>
            }
            </SharedHomeHeader>
            <DrawerBody p={"0"}>
              {
                (userType === "member" || userType === "contributor") ? (
                  <ShareHomeConnections 
                    recipients={clients}
                    menu={connectionsMenu}
                    onEdit={handleUpdateAccount}
                  />
                ) : (
                  <TabsHeader tabList={tabList} />
                )
              }
            </DrawerBody>
          </>
        )
      }
      <DeleteAssociationDialog associationId={associationId} openModal={deleteAccountModal} closeModal={handleCloseDeleteAccountModal} deleteAssociation={handleRemoveAccount} />
    </>
  )
};

export default ClientsListModule;
