import {useState, useEffect} from 'react';
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {HomeAssistantPanel, dynamicFormProxy, useCustomToast} from '@homefile/components';
import {ReportI} from '@homefile/components/dist/interfaces'
import { useGetFormsQuery, useGetProgressReportsQuery } from "../../../features/api";
import BeatLoader from "react-spinners/BeatLoader";
import {Center} from '@chakra-ui/react'
import {setPanel, setStep} from "../../../features/assistant/assistantSlice"
import { useGetDefaultConfig } from "../../../hooks/useGetDefaultConfig";
import { useCreateItem } from "../../../hooks/useCreateItem";
import { useUpdateItem } from "../../../hooks/useUpdateItem";
import {useHomeAssistant} from "../../../hooks/useHomeAssistant"
import { useGetForm } from "../../../hooks/useGetForm";

const HomeAssistantDrawer = ({ handleClose }: { handleClose: () => void }) => {
    const dispatch = useAppDispatch()
    const currentStep = useAppSelector((state: RootState) => state.assistant.currentStep)
    const currentPanel = useAppSelector((state: RootState) => state.assistant.currentPanel)
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const [loading, updateLoading] = useState(true)
    const [currentForm, updateCurrentForm] = useState<ReportI[]>([])
    const {defaultConfig} = useGetDefaultConfig()
    const [backDisabled, updateBackDisabled] = useState(true)
    const [nextDisabled, updateNextDisabled] = useState(true)
    const [selectedAppliances, updateSelectedAppliances] = useState<string[]>([])
    const toast = useCustomToast()
    const {getForm, form} = useGetForm()

    const successFunction = () => {
        toast({
            title: 'Success!',
            description: 'Form Saved',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: "top-right"
        })
    }

    const {handleCreateAndUpdate} = useCreateItem({ success: successFunction, completedForm: null })
    const {handleUpdate} = useUpdateItem(successFunction);
    const {forms,
        existingForms,
        completeNum,
        stepMapping,
        data
    } = useHomeAssistant()

    useEffect(() => {
        getForm('Household Item')
    }, [])


    useEffect(() => {
        if (forms[1]) {
            updateLoading(false)
            updateCurrentForm(forms[currentStep])
        }
        if (completeNum === 0) {
            updateNextDisabled(true)
            updateBackDisabled(true)
        } else if (currentStep <= completeNum) {
            updateNextDisabled(false)
            if (currentStep === 1 && completeNum < 6) {
                updateBackDisabled(true)
            } else {
                updateBackDisabled(false)
            }
        } else if (currentStep - 1 <= completeNum) {
            updateNextDisabled(true)
            updateBackDisabled(false)
        } else {
            updateNextDisabled(true)
            updateBackDisabled(true)
        }

    }, [forms, currentStep, completeNum])

    const onBack = () => {
        updateSelectedAppliances([])
        if (currentStep === 1) {
            dispatch(setPanel({currentPanel: 6}))
            dispatch(setStep({currentStep: 6}))
        } else {
            const back = currentStep - 1
            dispatch(setPanel({currentPanel: back}))
            dispatch(setStep({currentStep: back}))
        }

    }

    const onNext = () => {
        updateSelectedAppliances([])
        if (currentStep === 6) {
            dispatch(setPanel({currentPanel: 1}))
            dispatch(setStep({currentStep: 1}))
        } else {
            const next = currentStep + 1
            dispatch(setPanel({currentPanel: next}))
            dispatch(setStep({currentStep: next}))
        }
    }

    const onSave = (currentForm: ReportI[]) => {
        for (const step in stepMapping) {
            if (stepMapping[step] === currentStep) {
                const targetReport = existingForms.find((findForm: any) => findForm.title === step)
                if (targetReport) {
                    if (step === "Appliances" && selectedAppliances.length > 0) {
                        selectedAppliances.forEach((appliance: string) => {
                            handleCreateAndUpdate({
                                title: appliance,
                                type: defaultConfig.config.householdItemTypeId,
                                subType: defaultConfig.config.largeAppliancesSubTypeId,
                                folder: defaultConfig.config.householdItemsFolderId || "",
                                report: form.form,
                                formId: form._id, 
                                homeId
                            }) 
                        })
                    } 
                    handleUpdate({
                        newReportId: targetReport._id,
                        homeId,
                        reportTitle: step,
                        type: targetReport.type._id,
                        subType: targetReport.subType,
                        report: currentForm
                    })
                } else {
                    const targetObj = data.find((findForm: any) => findForm.name === step)
                    if (targetObj) {
                        if (step === "Appliances" && selectedAppliances.length > 0) {
                            selectedAppliances.forEach((appliance: string) => {
                                handleCreateAndUpdate({
                                    title: appliance,
                                    type: defaultConfig.config.householdItemTypeId,
                                    subType: defaultConfig.config.largeAppliancesSubTypeId,
                                    folder: defaultConfig.config.householdItemsFolderId || "",
                                    report: form.form,
                                    formId: form._id, 
                                    homeId
                                }) 
                            })
                        }

                        handleCreateAndUpdate({
                            title: step,
                            type: defaultConfig.config.assistantTypeId,
                            subType: targetObj.subType._id,
                            folder: defaultConfig.config.householdItemsFolderId || "",
                            report: currentForm,
                            formId: targetObj._id, 
                            homeId
                        })
                    }
                }
            }
        }
    }
        
    const onApplianceClick = (selected: string[]) => {
        updateSelectedAppliances(selected)
    }

    return (
        <>
            {
                loading ? (
                    <Center w={"100%"} h="4rem" bg="white">
                        <BeatLoader color="gray" size={8} />
                    </Center>
                ) : (
                    <HomeAssistantPanel 
                        currentForm={currentForm}
                        currentStep={currentStep}
                        onApplianceClick={onApplianceClick}
                        onBack={onBack}
                        onClose={handleClose}
                        onNext={onNext}
                        onSave={onSave}
                        backDisabled={backDisabled}
                        nextDisabled={nextDisabled}
                    />
                )
            }
        </>
    )
}

export default HomeAssistantDrawer