import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetRoomsByHomeQuery, useCreateRoomMutation } from '../../features/api';
import {RoomsMenu, RoomsMenuMobile} from '@homefile/components'
import {RoomItemI, AddRoomI} from '@homefile/components/dist/interfaces/rooms/RoomsMenu.interface'
import { useNavigate} from "react-router-dom";
import {RoomsToAdd} from '../../util/addRoomsList'
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import {ResponseType} from '../../interfaces/DataInterfaces'
import {openDialog} from '../../features/dialog/dialogSlice'
import { Box, Flex} from '@chakra-ui/react'
import {clearValue} from '../../features/room/roomSlice'

interface RoomNavI {
    id: string
}

const RoomNav = ({id}: RoomNavI) => {
    const dispatch = useAppDispatch()
    const {roomId} = useParams()
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const [skipValue, updateSkip] = useState(true)
    const {data} = useGetRoomsByHomeQuery(id, {skip: skipValue})
    const [rooms, updateRooms] = useState([] as RoomItemI[])
    const navigate = useNavigate();
    const [addRoom] = useCreateRoomMutation()
    const [activeRoom, updateRoom] = useState<string>("")
    const userType = useAppSelector((state: RootState) => state.auth.homeAssociationType)
    
    useEffect(() => {
        if (data) {
            updateRooms(data as RoomItemI[])
        }   
    }, [data])

    useEffect(() => {
        if (roomId) {
            const tRoom = rooms.filter((room: RoomItemI) => room._id === roomId)[0]
            if (tRoom) {
                updateRoom(tRoom._id)
            }
        } else {
            updateRoom("homeboard")
        }
    }, [rooms, roomId])

    useEffect(() => {
        if (id.length > 0) {
            updateSkip(false)
        } else {
            updateSkip(true)
        }
    }, [id])


    const handleClick = (room: RoomItemI) => {
        dispatch(clearValue())
        updateRoom(room._id)
        navigate(`/rooms/${room._id}`)
    }

    const handleHomeClick = () => {
        updateRoom("homeboard")
        navigate("/homes/base")
    }

    const handleRoomClick = async (type: string) => {
        
        if (type !== undefined) {
            const roomName = RoomsToAdd.find((room: AddRoomI) => room.type === type)
            const sendData = {
                name: roomName?.name,
                type: type,
                home: homeId
            }
    
    
            const newRoom: ResponseType = await addRoom(sendData)
    
            if (!newRoom.data) {
                console.log('new room failed', newRoom)
                dispatch(openDialog({message: newRoom.error.data.message}))
            } else {
                navigate(`/rooms/${newRoom.data._id}`)
            }
        }
    }

    return (
        <Box position="relative">
            <RoomsMenuMobile
                handleAddRoom={handleRoomClick}
                handleClick={handleClick}
                handleHomeClick={handleHomeClick}
                rooms={rooms}
                roomsToAdd={RoomsToAdd}
                activeRoom={activeRoom}
            />
            <Flex height={"100%"} minH={['fit-content', 'calc(100vh)']}>
                <RoomsMenu 
                    handleClick={handleClick} 
                    rooms={rooms} 
                    roomsToAdd={RoomsToAdd}
                    handleAddRoom={handleRoomClick}
                    handleHomeClick={handleHomeClick}
                    activeRoom={activeRoom}
                    disabled={userType === "member"}
                />
            </Flex>
        </Box>
    )
}

export default RoomNav