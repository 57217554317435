import { useState, useEffect } from "react";
import {
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  Box
} from "@chakra-ui/react";
import { clearDrawer } from "../../../features/drawer/drawerSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store";
import {PanelHeader, CreateHouseholdItemHeader, ItemNameHeader, FooterDrawer, FooterButtons, Overlay, dynamicFormProxy, useCustomToast, ReadOnlyDynamicForm, ReadOnlyToggle, ReadOnlyImage, AddHomeItemHeader} from '@homefile/components';
import { Create } from "../../../assets/images";
import { useGetForm } from "../../../hooks/useGetForm";
import { t } from 'i18next';
import {useGetRoomsByHomeQuery} from '../../../features/api';
import {RoomItemI} from '@homefile/components/dist/interfaces/rooms/RoomsMenu.interface'
import { SelectItemI, ReportsI, ReportI } from "@homefile/components/dist/interfaces";
import CreateHouseholdItemTabs from './CreateHouseholdItemTabs'
import { useCreateItem } from "../../../hooks/useCreateItem";
import { useUpdateItem } from "../../../hooks/useUpdateItem";
import { useGetHouseholdItemSubTypes } from "../../../hooks/useGetHouseholdItemSubTypes";
import { useGetDefaultConfig } from "../../../hooks/useGetDefaultConfig";
import { useUploadMedia } from "../../../hooks/useUploadMedia";
import HouseholdItemWizard from "./HouseholdItemWizard";
import {useHasPermission} from '../../../hooks/useHasPermission'

const CreateHouseholdItemDrawer = ({sentForm}: any) => {
    const dispatch = useAppDispatch();
    const {getForm, form, resetForm} = useGetForm()
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const userType = useAppSelector((state: RootState) => state.auth.homeAssociationType)
    const [skipValue, updateSkip] = useState(true)
    const {data } = useGetRoomsByHomeQuery(homeId, {skip: skipValue})
    const [rooms, updateRooms] = useState([] as RoomItemI[])
    const [filteredRooms, updateFilteredRooms] = useState([] as RoomItemI[])
    const [selectedGroupId, updateGroupId] = useState<string>("")
    const [selectedRoom, updateRoom] = useState({} as SelectItemI)
    const [itemName, updateName] = useState("")
    const toast = useCustomToast()
    const [initRoom, updateInitRoom] = useState({} as SelectItemI)
    const [initGroup, updateInitGroup] = useState("" as string)
    const {itemSubTypes} = useGetHouseholdItemSubTypes(homeId)
    const [newCompleted, updateNew] = useState({} as any)
    const [roomFilterValue, updateRoomFilter] = useState("")
    const [groupFilterValue, updateGroupFilter] = useState("")
    const {defaultConfig} = useGetDefaultConfig()
    const [filteredGroups, updateFilteredGroups] = useState([] as SelectItemI[])
    const [showWizard, updateShowWizard] = useState(false)
    const [showOverlay, updateOverlay] = useState(true)
    const [completedForm, updateCompletedForm] = useState(sentForm)
    const [editMode, updateEditMode] = useState(true)
    const [readOnlyImages, updateReadOnlyImages] = useState<string[]>([])
    const {images} = useUploadMedia({documentId: completedForm._id})
    const {checkPermission} = useHasPermission()

    useEffect(() => {
        if (homeId.length > 0) {
            updateSkip(false)
        } else {
            updateSkip(true)
        }
    }, [homeId])
    
    const successFunction = () => {

        toast({
            title: 'Success!',
            description: 'Item Updated',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: "top-right"
          })
      }

      const createSuccessFunction = (data: any) => {
        toast({
            title: 'Success!',
            description: 'Item Created',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: "top-right"
        })
        if (!checkPermission("PatchReportId")) {
            handleClose()
        } else {
            updateNew(data)
            updateOverlay(false)
        }
      }

      const {handleUpdate} = useUpdateItem(successFunction);

      const {handleCreateAndUpdate, newItemId, updateId} = useCreateItem({ 
        success: createSuccessFunction,
        completedForm
      })

    useEffect(() => {
        // use here to update init values for rooms and form title
        if (data) {
            updateRooms(data as RoomItemI[])
            if (completedForm.title !== undefined) {
                updateName(completedForm.title)
                updateShowWizard(false)
            } 
        }  
    }, [data, completedForm.title])

    useEffect(() => {
        // init and select value for room
        if (rooms.length > 0) {
            if (completedForm.room) {
                const targetRoom: SelectItemI = rooms.filter((rooom: SelectItemI) => rooom._id === completedForm.room)[0]
                updateInitRoom(targetRoom)
                updateRoom(targetRoom)
            } else if (newCompleted.room) {
                const targetRoom: SelectItemI = rooms.filter((rooom: SelectItemI) => rooom._id === newCompleted.room)[0]
                updateRoom(targetRoom)
            } else {
                updateInitRoom(rooms[0])
                updateRoom(rooms[0])
            }
        }
    }, [rooms, completedForm.room, newCompleted.room])

    useEffect(() => {
        // init and select value for group
        if (itemSubTypes.length > 0) {
            if (completedForm.subType && completedForm.type !== defaultConfig.config.receiptItemTypeId && newCompleted.subType === undefined) {
                const targetGroup: SelectItemI = itemSubTypes.filter((type: SelectItemI) => type._id === completedForm.subType)[0]
                updateInitGroup(targetGroup.name)
                updateGroupId(targetGroup._id)
            } else if (newCompleted.subType) {
                const targetGroup: SelectItemI = itemSubTypes.filter((type: SelectItemI) => type._id === newCompleted.subType)[0]
                updateGroupId(targetGroup._id)
            }  else {
                const targetGroup: SelectItemI | undefined = itemSubTypes.find((type: SelectItemI) => type.name === "Uncategorized")
                if (targetGroup) {
                    updateInitGroup(targetGroup.name)
                    updateGroupId(targetGroup._id)
                } else {
                    updateInitGroup(itemSubTypes[0].name)
                    updateGroupId(itemSubTypes[0]._id)
                }
            }
        }
    }, [itemSubTypes, completedForm.subType, newCompleted.subType])

    useEffect(() => {
        if (rooms.length > 0) {
            const sortRooms = [...rooms].sort((a,b) => a.name.localeCompare(b.name))
            const updatedRooms = sortRooms.filter((room: RoomItemI) => room.name.toLowerCase().includes(roomFilterValue.toLowerCase()))
            updateFilteredRooms([...updatedRooms])
        }
    }, [rooms, roomFilterValue])

    useEffect(() => {
        const updatedGroups = itemSubTypes.filter((group: SelectItemI) => group.name.toLowerCase().includes(groupFilterValue.toLowerCase()))
        updateFilteredGroups([...updatedGroups])
    }, [itemSubTypes, groupFilterValue])

    useEffect(() => {
        getForm('Household Item')
    }, [form])

    const handleSelectRoom = (room: string | SelectItemI) => {
        if (typeof room === "object") {
            updateRoom(room)
        } else {
            const targetRoom: SelectItemI = rooms.filter((type: SelectItemI) => type.name === room)[0]
            updateRoom(targetRoom)
        }
    }

    const handleSelectGroup = (group: string | SelectItemI) => {
        if (typeof group === "object") {
            updateGroupId(group._id)
        } else {
            const targetGroup: SelectItemI = itemSubTypes.filter((type: SelectItemI) => type.name === group)[0]
            updateGroupId(targetGroup._id)
        }
        
    }


    const duplicate = () => {
        updateId("")
        const {fields} = dynamicFormProxy
        if (itemName.length > 0) {
            handleCreateAndUpdate({
                title: itemName,
                type: defaultConfig.config.householdItemTypeId,
                subType: selectedGroupId,
                folder: defaultConfig.config.householdItemsFolderId || "",
                room: selectedRoom._id,
                report: fields,
                formId: form._id, 
                homeId
          })

        } else {
            toast({
              title: 'Error!',
              description: 'Add Item Name to Save Item',
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: "top-right"
            })
        }
    }

    const clear = () => {
        if (newItemId !== "") {

            updateNew({} as ReportsI)
            updateCompletedForm({})
            updateName("")
            updateId("")
            resetForm()

        } else {
            toast({
                title: 'Error!',
                description: 'Add Item Name to Save Item',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top-right"
              })
        }
    }


    const handleSave = () => {
        const {fields} = dynamicFormProxy
        if (itemName.length > 0) {
            if (newItemId === "" && completedForm._id === undefined) {
                handleCreateAndUpdate({
                    title: itemName,
                    type: defaultConfig.config.householdItemTypeId,
                    subType: selectedGroupId,
                    folder: defaultConfig.config.householdItemsFolderId || "",
                    room: selectedRoom._id,
                    report: fields,
                    formId: form._id, 
                    homeId
              })
    
            } else if ((completedForm.title !== undefined || newCompleted.title !== undefined)) {
                const id = completedForm._id ? completedForm._id : newCompleted._id
                const sendType = completedForm.type ? completedForm.type : defaultConfig.config.householdItemTypeId
                handleUpdate({
                    newReportId: id,
                    homeId,
                    reportTitle: itemName,
                    type: sendType,
                    subType: selectedGroupId,
                    room: selectedRoom._id,
                    report: fields,
                })
            }
        } else {
            toast({
                title: 'Error!',
                description: 'Add Item Name to Save Item',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top-right"
            })
        }
    }

    const handleWizardSave = () => {
        if (itemName.length > 0) {
            handleCreateAndUpdate({
                title: itemName,
                type: defaultConfig.config.householdItemTypeId,
                subType: selectedGroupId,
                folder: defaultConfig.config.householdItemsFolderId || "",
                room: selectedRoom._id,
                report: form.form,
                formId: form._id, 
                homeId
          })
        } else {
            toast({
              title: 'Add Item Name to Save Item',
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: "top-right"
            })
        }
    }

    const handleWizardUpdate = (flag: boolean) => {
        const {fields} = dynamicFormProxy
        if (flag) {
            const id = completedForm._id ? completedForm._id : newCompleted._id
            const sendType = completedForm.type ? completedForm.type : defaultConfig.config.householdItemTypeId
            handleUpdate({
                newReportId: id,
                homeId,
                reportTitle: itemName,
                type: sendType,
                subType: selectedGroupId,
                room: selectedRoom._id,
                report: fields
            })
        }
    }
    

    const ButtonsFooter = {
        button1: {
            buttonStyle: 'primaryFooter',
            label: t('Save'),
            onClick: () => {handleSave()},
        },
        button2: {
            buttonStyle: 'secondaryFooter',
            label: t('Duplicate'),
            onClick: () => {duplicate()},
        },
            button3: {
            buttonStyle: 'secondaryFooter',
            label: t('Add Another'),
            onClick: () => {clear()},
        },
      }

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateName(event.target.value)
    }

    const handleRoomFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateRoomFilter(e.target.value)
    }

    const handleGroupFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateGroupFilter(e.target.value)
    }

    const handleClose = () => {
        dispatch(clearDrawer())
    }

    useEffect(() => {
        if (images.length > 0) {
            const { REACT_APP_STORAGE_URL: storageUrl } = process.env
            let imagesToShow: string[] = []
            images.forEach((file: any) => {
                const imageUrl = `${storageUrl}/${file?.bucketName}/${file?.fileName}.${file?.extension}`
                imagesToShow.push(imageUrl)
            })
            updateReadOnlyImages(imagesToShow)
        }
    }, [images])

    useEffect(() => {
        if (completedForm._id) {
            updateOverlay(false)
            updateEditMode(false)
        } else {
            updateOverlay(true)
            updateEditMode(true)
        }
      }, [completedForm])


    return (
        <DrawerContent>
            {
                showWizard ? <HouseholdItemWizard 
                        handleClose={handleClose} 
                        handleTurnOffWizard={() => updateShowWizard(false)}
                        categories={filteredGroups}
                        rooms={filteredRooms}
                        handleCategoryChange={handleSelectGroup}
                        handleRoomChange={handleSelectRoom}
                        initialCategory={initGroup}
                        initialRoom={initRoom.name}
                        nameValue={itemName}
                        handleNameChange={handleNameChange}
                        newItemId={newItemId}
                        report={form}
                        handleAnother={clear}
                        handleSave={handleWizardSave}
                        newCompleted={newCompleted}
                        handleUpdate={handleWizardUpdate}
                    /> : <>
                    {
                        <>
                        {
                            (editMode && form.name) && (
                                <>
                                    <DrawerHeader p="0">
                                        <PanelHeader
                                            handleCloseButton={handleClose}
                                            icon={Create}
                                            title={completedForm._id ? "Edit Home Item" : form.name}
                                            // hasButton={(completedForm.title || newCompleted.title) ? false : true}
                                            // buttonLabel="Show Wizard"
                                            // buttonClick={() => updateShowWizard(true)}
                                        />
                                    </DrawerHeader>

                                    <DrawerBody padding="0" bg="lightBlue.1">
                                    <AddHomeItemHeader 
                                                handleSave={handleSave} 
                                                value={itemName} 
                                                handleChange={handleNameChange}
                                                handleSelectGroup={handleSelectGroup}
                                                handleSelectRoom={handleSelectRoom}
                                                rooms={filteredRooms}
                                                initialRoom={initRoom}
                                                groups={filteredGroups} 
                                                initialGroup={initGroup}
                                                activeGroup={true}
                                                activeRoom={true}
                                                roomHasFilter={true}
                                                groupHasFilter={true}
                                                roomFilterValue={roomFilterValue}
                                                roomHandleFilter={handleRoomFilter}
                                                groupFilterValue={groupFilterValue}
                                                groupHandleFilter={handleGroupFilter}
                                            />
                                        <Box display="block" position="relative">
                                            <Overlay showOverlay={showOverlay} position="absolute" w="inherit" h="inherit" zIndex="9"/>
                                            <CreateHouseholdItemTabs 
                                                reportId={!completedForm._id ? newItemId : completedForm._id} 
                                                reportTitle={itemName} 
                                                homeId={homeId} 
                                                report={!completedForm.title ? form : completedForm}
                                                wizardTab={false}
                                                detailedWizard={false}
                                                showMediaIcon={true}
                                            />
                                        </Box>

                                    </DrawerBody>

                                </>
                            )
                        }
                        {
                            (!editMode && form.name) && (
                                <>
                                    <DrawerHeader p="0">
                                        <PanelHeader
                                            handleCloseButton={handleClose}
                                            icon={Create}
                                            title={completedForm._id ? "View Home Item" : form.name}
                                        />
                                    </DrawerHeader>

                                    <DrawerBody padding="0" bg="lightBlue.1">
                                        <ReadOnlyToggle canEdit={checkPermission("PatchReportId")} onEdit={() => {updateEditMode(true)}} value={itemName} />
                                        <ReadOnlyImage 
                                            name={readOnlyImages.length > 0 ? "Images" : ""}
                                            value={readOnlyImages}
                                        />
                                        <ReadOnlyDynamicForm 
                                            form={completedForm.form ? completedForm.form : completedForm.report}
                                        />

                                    </DrawerBody>
                                </>
                                
                            )
                        }
                            
                        </>
                    }
                    {
                        checkPermission("PatchReportId") && (
                            <DrawerFooter zIndex={"999"}>
                                <FooterDrawer
                                    children={
                                        <FooterButtons
                                        button1={ButtonsFooter.button1}
                                        button2={ButtonsFooter.button2}
                                        button3={ButtonsFooter.button3}
                                        />
                                    }
                                    isOpen={!showOverlay}
                                    />

                            </DrawerFooter>
                        )
                    }
                </>
            }
        </DrawerContent>
    )
}

export default CreateHouseholdItemDrawer