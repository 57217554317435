import { useHomeAddAccountMutation, useHomeDeleteAccountMutation, useHomeUpdateAccountMutation } from "../features/api";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { RootState } from "../app/store";
import { ResponseType } from "../interfaces/DataInterfaces";
import { openDialog } from "../features/dialog/dialogSlice";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { setDrawer } from "../features/drawer/drawerSlice";
import { AssociatedAccountI } from "@homefile/components/dist/interfaces/shareHome/ShareHome.interface";
import {useCustomToast} from '@homefile/components'

interface FullAcountI extends AssociatedAccountI {
  _id?: string
  email?: string
}

export const useAssociatedAccount = (accounts: FullAcountI[]) => {
  const homeId = useAppSelector((state: RootState) => state.home.id);
  const [addClient] = useHomeAddAccountMutation()
  const [removeClient] = useHomeDeleteAccountMutation()
  const dispatch = useAppDispatch()
  const [loading, updateLoading] = useState(false)
  const toast = useCustomToast()
  const [associationId, updateAssociationId] = useState("")
  const [deleteAccountModal, updateDeleteAccountModal] = useState(false)
  const [updateAccount] = useHomeUpdateAccountMutation()
  const {
    isOpen: isRightOpen,
    onOpen: onRightOpen,
    onClose: onRightClose,
  } = useDisclosure()

  const [editingAccountType, setEditingAccountType] = useState<AssociatedAccountI>({} as AssociatedAccountI)

  const handleEdit = (editingAccount: any) => {
    const targetUser = accounts.find((association: FullAcountI) => association.user.email === editingAccount._id)
    if (targetUser) {
      onRightOpen()
      setEditingAccountType(targetUser)
    }
  }

    const handleAdd = async (props: AssociatedAccountI) => {
        updateLoading(true)
        const {user, accountTypes} = props
        const existingClient = accounts.filter((client: FullAcountI) => client.user.email.toLowerCase() === user.email.toLowerCase())
        if (existingClient.length > 0) {

          updateLoading(false)
          toast({
            title: 'Error',
            description: 'Account is already connected',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: "top-right"
          })
        } else {
          const permissions = accountTypes[0].toLowerCase()
          const sendData = {
            home: homeId,
            email: user.email.toLowerCase(),
            accountTypes: accountTypes[0],
            permissions
          };
      
          const newClient: ResponseType = await addClient(sendData);
      
          if (newClient.data) {
            updateLoading(false)
            toast({
                title: 'Success',
                description: 'Account connected!',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top-right"
              })
          } else {
            updateLoading(false)
            dispatch(openDialog({message: newClient.error.data.message}))
          }
        }
    
    }

    const handleCloseDeleteAccountModal = () => {
      updateLoading(false)
      updateDeleteAccountModal(false)
    }
  
    const openRemoveDialog = (account: any) => {
      updateLoading(true)
      const targetUser = accounts.find((association: FullAcountI) => association.user.email === account._id)
      if (targetUser !== undefined && targetUser.user !== undefined && targetUser.user._id !== undefined) {
        updateAssociationId(targetUser.user._id)
        updateDeleteAccountModal(true)
      }
    }

    const openEditRemove = (email: string) => {
      updateLoading(true)
      const targetUser = accounts.find((association: FullAcountI) => association.user.email === email)
      if (targetUser !== undefined && targetUser.user !== undefined && targetUser.user._id !== undefined) {
        updateAssociationId(targetUser.user._id)
        updateDeleteAccountModal(true)
      }
    }

    const openRemoveDialogWithAccount = (account: AssociatedAccountI) => {
      const targetUser = accounts.find((association: FullAcountI) => association.user.email === account.user.email)
      if (targetUser !== undefined && targetUser.user !== undefined && targetUser.user._id !== undefined) {
        updateAssociationId(targetUser.user._id)
        updateDeleteAccountModal(true)
      }
    }
  
    const handleRemoveAccount = async (id: string) => {
      updateLoading(true)
        const sendData = {
          home: homeId,
          user: id
        }
        const client: ResponseType = await removeClient(sendData)
  
        updateDeleteAccountModal(false)
  
        if (client.data) {
          updateLoading(false)
          toast({
            title: 'Success',
            description: `Account Removed`,
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: "top-right"
          })
        } else {
          updateLoading(false)
          dispatch(openDialog({message: client.error.data.message}))
        }
  
  
    }

    const handleShowAccounts = () => {
        dispatch(setDrawer({ location: "right", content: "AccountList", size: "md" }));
    }

    const handleUpdateAccount = async (account: any) => {
      updateLoading(true)
      const targetUser = accounts.find((association: FullAcountI) => association.user.email === account.user.email)
      if (targetUser !== undefined && targetUser.user !== undefined && targetUser.user._id !== undefined) {
        const permissions = account.accountType.toLowerCase()
        const sendData = {
          home: homeId,
          association: targetUser._id,
          accountTypes: account.accountType,
          permissions
        }
  
        const updatedAccount: ResponseType = await updateAccount(sendData)
    
          if (updatedAccount.data) {
            updateLoading(false)
            toast({
              title: 'Success',
              description: `Account Updated`,
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: "top-right"
            })
          } else {
            updateLoading(false)
            dispatch(openDialog({message: updatedAccount.error.data.message}))
          }
      }
    }

    return {
        handleAdd,
        handleShowAccounts,
        openRemoveDialog,
        handleCloseDeleteAccountModal,
        handleRemoveAccount,
        deleteAccountModal,
        associationId,
        openRemoveDialogWithAccount,
        handleUpdateAccount,
        loading,
        isRightOpen,
        onRightClose,
        onRightOpen,
        handleEdit,
        editingAccountType,
        openEditRemove
    }
}