import { homefileApi } from './emptySplitApi'

const homeApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        createHome: builder.mutation({
            query: (data: any) => ({
              url: "/api/home",
              method: "POST",
              body: data,
              headers: {
                "Content-Type": "application/json",
              },
            }),
            invalidatesTags: ["Homes", "User"],
        }),
        getHomes: builder.query({
            query: () => ({
              url: "/api/home/list",
              headers: {
                Pagination: '{"page": 1, "limit": 100}',
              },
            }),
            providesTags: ["Homes"],
        }),
        getHome: builder.query({
            query: (id?: string) => ({
              url: `/api/home/${id}`,
            }),
            providesTags: ["Home", "Homes"],
        }),
        getHomePropertyInfo: builder.query({
          query: () => ({
            url: `/api/home/info`,
          }),
          providesTags: ["PropertyData"],
        }),
        searchHomePropertyInfo: builder.query({
          query: (data: any) => ({
            url: `/api/home/real-state?house="${data.house}"&street="${data.street}"&city="${data.city}"&state="${data.state}"&zip="${data.zip}"`,
          }),
          providesTags: ["PropertyData"],
        }),
        addHomePropertyInfo: builder.mutation({
          query: (data: any) => ({
            url: `/api/home/real-state`,
            method: "POST",
            body: data,
          }),
          invalidatesTags: ["PropertyData"],
        }),
        editHomePropertyInfo: builder.mutation({
          query: (data: any) => ({
            url: `/api/home/real-state`,
            method: "PATCH",
            body: data,
          }),
          invalidatesTags: ["PropertyData"],
        }),
        updateHome: builder.mutation({
            query: (data: any) => ({
              url: `/api/home/${data.homeId}`,
              method: "PATCH",
              body: data.home,
              headers: {
                "Content-Type": "application/json",
              },
            }),
            invalidatesTags: ["Home", "Homes"],
        }),
        homeAddAccount: builder.mutation({
            query: (data: any) => ({
              url: `/api/user-association/home`,
              method: "POST",
              body: data,
              headers: {
                "Content-Type": "application/json",
              },
            }),
            invalidatesTags: ["Home", "Homes"],
        }),
        homeDeleteAccount: builder.mutation({
            query: (data: any) => ({
              url: '/api/user-association/home',
              method: "DELETE",
              body: data
            }),
            invalidatesTags: ["Home", "Homes", "ProgressReport", "ProgressReports"],
        }),
        homeUpdateAccount: builder.mutation({
            query: (data: any) => ({
              url: '/api/user-association/home',
              method: "PATCH",
              body: data
            }),
            invalidatesTags: ["Home", "Homes", "ProgressReport", "ProgressReports"],
        }),
        getHomeAssociation: builder.query({
            query: (homeId: string) => ({
              url: `/api/user-association/home/${homeId}`  
            }),
            providesTags: ["Folders", "Forms"]
        }),
        homeAddPermissions: builder.mutation({
          query: (data: any) => ({
            url: `/api/user-association/default-roles-permissions`,
            method: "POST",
            body: data.body,
            headers: {
              "home": `${data.homeId}`,
            }
          }),
          invalidatesTags: ["Home", "Homes"],
        }),
        homeUpdatePermissions: builder.mutation({
          query: (data: any) => ({
            url: '/api/user-association/default-roles-permissions',
            method: "PATCH",
            body: data
          }),
          invalidatesTags: ["Home", "Homes"],
        }),
        archiveHome: builder.mutation({
            query: (id: string) => ({
              url: `/api/home/${id}`,
              method: "DELETE",
            }),
            invalidatesTags: ["Homes", "Home", "User"],
        }),
        deleteHome: builder.mutation({
            query: (id: string) => ({
              url: `/api/home/exclude/${id}`,
              method: "DELETE",
            }),
            invalidatesTags: ["Homes", "Home", "User"],
        }),
        restoreHome: builder.mutation({
            query: (id: string) => ({
              url: `/api/home/restore/${id}`,
              method: "PATCH",
            }),
            invalidatesTags: ["Homes", "Home", "User"],
        }),
    }),
    overrideExisting: false,
})
  
export const { 
    useCreateHomeMutation,
    useGetHomesQuery,
    useUpdateHomeMutation,
    useGetHomeQuery,
    useArchiveHomeMutation,
    useHomeAddAccountMutation,
    useHomeDeleteAccountMutation,
    useGetHomeAssociationQuery,
    useHomeUpdateAccountMutation,
    useDeleteHomeMutation,
    useRestoreHomeMutation,
    useGetHomePropertyInfoQuery,
    useSearchHomePropertyInfoQuery,
    useAddHomePropertyInfoMutation,
    useEditHomePropertyInfoMutation,
    useHomeUpdatePermissionsMutation,
    useHomeAddPermissionsMutation
} = homeApi