import {ICreateItem, CreateFunctionI } from '../interfaces/CreateDocumentInterfaces';
import { ResponseType } from '../interfaces/DataInterfaces';
import {useState, useEffect} from 'react';
import {
    useCreateProgressReportMutation,
    useGetItemSubTypesByItemTypeQuery
  } from "../features/api";
import { useAppDispatch } from "../app/hooks";
import { openDialog } from "../features/dialog/dialogSlice";
import { useUpdateItem } from "./useUpdateItem";
import { useRoomSubType } from './useRoomSubType';
import { SelectItemI, ReportsI } from "@homefile/components/dist/interfaces";
import { useGetDefaultConfig } from "../hooks/useGetDefaultConfig";
import {useHasPermission} from './useHasPermission'

export const useCreateItem = (props: ICreateItem) => {
    const {success, formType, completedForm} = props
    const dispatch = useAppDispatch();
    const [itemTitle, updateTitle] = useState("");
    const [createReport] = useCreateProgressReportMutation();
    const [newItemId, updateId] = useState("");
    const {addRoomSubType} = useRoomSubType()
    const [initSubType, updateInitSubType] = useState({} as SelectItemI)
    const [skip, updateSkip] = useState(true)
    const {data} = useGetItemSubTypesByItemTypeQuery(formType || "", {skip})
    const [subTypes, updateSubsTypes] = useState([] as any)
    const [subTypeLabel, updateLabel] = useState("")
    const [selectedSubType, updateSelectedSubType] = useState("")
    const [itemName, updateName] = useState("")
    const {handleUpdate} = useUpdateItem(success);
    const [typeId, updateTypeId] = useState("")
    const [folderId, updateFolderId] = useState("")
    const {defaultConfig} = useGetDefaultConfig()
    const {checkPermission} = useHasPermission()

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateName(event.target.value)
    }

    const handleSubTypeChange = (subType: SelectItemI | string) => {
      if (typeof subType === "object") {
        updateSelectedSubType(subType._id)
      }
    }

    useEffect(() => {
        if (formType) {
            updateSkip(false)
        }
    }, [formType])

    useEffect(() => {
        if (data) {
          updateSubsTypes(data)
        }
      }, [data])
  
      useEffect(() => {
        if (subTypes.length > 1) {
          updateLabel("Category")
          if (completedForm && completedForm.title) {
            const targetSubType: SelectItemI = subTypes.filter((type: SelectItemI) => type._id === completedForm.subType)[0]
            updateInitSubType(targetSubType)
            updateSelectedSubType(targetSubType._id)
          } else {
            updateInitSubType(subTypes[0])
            updateSelectedSubType(subTypes[0]._id)
          }
        }
      }, [subTypes, completedForm])

      useEffect(() => {
        // use here to update init value for form title
        if (completedForm !== null) {
          if (completedForm.title !== undefined) {
            updateName(completedForm.title)
          } else {
            updateName("")
          }
        } 
    }, [completedForm])

    useEffect(() => {
        if (defaultConfig.config && formType) {
          updateTypeId(formType)
          if (formType === defaultConfig.config.noteTypeId) {
            updateFolderId(defaultConfig.config.notesFolderId)
          } else {
            updateFolderId(defaultConfig.config.contactsFolderId)
          }
        }
      }, [defaultConfig, formType])


    const handleCreateAndUpdate = async ({
        title,
        type,
        subType,
        folder,
        room,
        report,
        homeId,
        formId
    }: CreateFunctionI) => {

        const sendData = {
            home: homeId,
            reportForm: formId,
            title,
            type,
            subType,
            folder,
            room
        }

        const newReport: ResponseType = await createReport(sendData);
        if (newReport.data) {
            updateId(newReport.data._id);
            updateTitle(title);
            if (checkPermission("PostRoomSubType")) {
              if (room !== undefined && subType !== undefined) {
                addRoomSubType({
                    room,
                    subType: subType
                })
              }
            }
            if (!checkPermission("PatchReportId")) {
              success(newReport.data)
            } else {
              handleUpdate({
                newReportId: newReport.data._id,
                homeId,
                reportTitle: title,
                report
              })
            }
        } else {
            console.log("error", newReport);
            dispatch(openDialog({message: newReport.error.data.message}))
        }
    }

    return {
        newItemId,
        itemTitle,
        handleCreateAndUpdate,
        itemName,
        subTypes,
        initSubType,
        subTypeLabel,
        selectedSubType,
        handleNameChange,
        handleSubTypeChange,
        typeId,
        folderId,
        defaultConfig,
        updateId
    }
}