import { useState, useEffect} from "react";
import {useParams} from 'react-router-dom'
import { RoomItemI, SelectItemI, GroupOptionI, ReportsI } from '@homefile/components/dist/interfaces';
import { useGetRoomQuery, useRemoveSubTypeFromRoomMutation } from '../features/api';
import { useGetHouseholdItemSubTypes } from './useGetHouseholdItemSubTypes';
import { GroupCardsI } from "../interfaces/Room.interfaces";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { addValue, clearValue } from "../features/room/roomSlice";
import { RootState } from '../app/store';
import { useGetDefaultConfig } from "./useGetDefaultConfig";
import { openDialog } from "../features/dialog/dialogSlice";
import { useNavigate} from "react-router-dom";

export const useRoomData = () => {
    const {roomId} = useParams()
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const dispatch = useAppDispatch()
    const {data, error} = useGetRoomQuery(roomId || "")
    const [groups, updateGroups] = useState<GroupOptionI[]>([])
    const [activeGroups, updateActiveGroups] = useState<GroupCardsI[]>([])
    const [roomData, updateRoomData] = useState({} as RoomItemI)
    const [isLoading, updateLoading] = useState(true)
    const {itemSubTypes} = useGetHouseholdItemSubTypes(homeId)
    const [removeGroup] = useRemoveSubTypeFromRoomMutation()
    const {defaultConfig} = useGetDefaultConfig()
    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            updateRoomData(data as RoomItemI)
            updateLoading(false)
        } else if (error) {
            if ('status' in error) {
                if (error.status === 403) {
                    dispatch(openDialog({message: "Error: access denied, no permissions to view rooms."}))
                    navigate("/homes/base")
                }
            }
        }
    }, [data, error])

    const handleRemove = (group: SelectItemI) => {
        removeGroup({
            room: roomData._id,
            subType: group._id
        })
    }


    
    useEffect(() => {
        updateGroups([])
        if (roomData && itemSubTypes && itemSubTypes.length > 0) {
            // FIRST FOR ADDING/REMOVING GROUPS 
            if (roomData.subTypes !== undefined && roomData.subTypes.length > 0) {
                // if there are room groups selected that may be empty
                itemSubTypes.forEach((item: SelectItemI) => {
                    if (item.name !== "Uncategorized") {
                        if (roomData.subTypes !== undefined) {
                            const testArray = roomData.subTypes.filter((group: string) => group === item._id)
                            if (testArray.length > 0) {
                                const newItem: GroupOptionI = {
                                    _id: item._id,
                                    name: item.name,
                                    active: false,
                                    home: homeId,
                                    room: roomId || ""
                                }
                                updateGroups((groups) => [...groups, newItem])
                            } else {
                                const newItem: GroupOptionI = {
                                    _id: item._id,
                                    name: item.name,
                                    active: true,
                                    home: homeId,
                                    room: roomId || ""
                                }
                                updateGroups((groups) => [...groups, newItem])
                            }
                        }
                    }
                })
            } else {
                // if there are no room groups selected - only populate the group list with the categories that are not default
                itemSubTypes.forEach((item: SelectItemI) => {
                    if (item.name !== "Uncategorized") {
                        const newItem: GroupOptionI = {
                            _id: item._id,
                            name: item.name,
                            active: true,
                            home: homeId,
                            room: roomId || ""
                        }
                        updateGroups((groups) => [...groups, newItem])
                    }
                })
            }
        }

        // END OF GROUP SLIDER LOGIC

        // NEXT FOR DETERMINING ACTIVE GROUPS WITH ITEMS

        // pass groups to state after determining active groups
        let mappedGroups = [] as GroupCardsI[]

        // first if the group has items in it
        if (roomData.items !== undefined && roomData.items.length > 0) {
            dispatch(clearValue())
            itemSubTypes.forEach((group: SelectItemI) => {
                roomData.items!.forEach((item: any) => {
                    if (group._id === item.subType) {
                        const inGroups = mappedGroups.filter((mGroup: GroupCardsI) => mGroup.title === group.name)
                        if (inGroups.length > 0) {
                            if (!item.deleted) {
                                if (item.type !== defaultConfig.config.receiptItemTypeId) {
                                    if (item.report) {
                                        if (item.report.length === 6 || item.report.length === 7) {
                                            const costField = item.report.find((field: any) => field.name === "Purchase Details").children[2].children.find((aField: any) => aField.name === "Amount Paid for Item")
                                            const value = costField.value ? costField.value : 0;
                                            inGroups[0].totalAmount += value
                                            dispatch(addValue({value}))
                                        } else {
                                            const costField = item.report.find((field: any) => field.name === "Amount Paid for Item")
                                            const value = costField.value !== "" ? costField.value : 0;
                                            inGroups[0].totalAmount += value
                                            dispatch(addValue({value}))
                                        }
                                    }
                                    inGroups[0].items = [...inGroups[0].items, item]
                                } else {
                                    // receipt item here - need to get value of it 
                                    const value: any = Number(item.metadata.total)
                                    inGroups[0].items = [...inGroups[0].items, item]
                                    inGroups[0].totalAmount += value
                                    dispatch(addValue({value}))
                                }
                            }
                        } else {
                            if (!item.deleted) {
                                if (item.type !== defaultConfig.config.receiptItemTypeId) {
                                    let value = 0
                                    if (item.report) {
                                        if (item.report.length === 6 || item.report.length === 7) {
                                            const costField = item.report.find((field: any) => field.name === "Purchase Details").children[2].children.find((aField: any) => aField.name === "Amount Paid for Item")
                                            value = costField.value ? costField.value : 0;
                                        } else {
                                            const costField = item.report.find((field: any) => field.name === "Amount Paid for Item")
                                            value = costField.value !== "" ? costField.value : 0;
                                        }
                                    }
                                    const addGroup: GroupCardsI = {
                                        items: [item],
                                        title: group.name,
                                        totalAmount: value,
                                        _id: group._id
                                    }
                                    dispatch(addValue({value}))
                                    mappedGroups = [...mappedGroups, addGroup]
                                } else {
                                    // receipt item here - need to get value of it 
                                    const value: any = Number(item.metadata.total)
                                    const addGroup: GroupCardsI = {
                                        items: [item],
                                        title: group.name,
                                        totalAmount: value,
                                        _id: group._id
                                    }
                                    dispatch(addValue({value}))
                                    mappedGroups = [...mappedGroups, addGroup]
                                }
                            }
                        }
                    }
                })
            })
        } 

        // next if the group is part of the active groups from the roomData.subTypes array of ids
        if (roomData.subTypes !== undefined && roomData.subTypes.length > 0 && itemSubTypes.length > 0) {
            roomData.subTypes!.forEach((existingGroup: string) => {
                const targetGroup: SelectItemI = itemSubTypes.filter((group: SelectItemI) => group._id === existingGroup)[0]
                if (targetGroup) {
                    const inGroups = mappedGroups.filter((mGroup: GroupCardsI) => mGroup.title === targetGroup.name)
                    if (inGroups.length === 0) {
                        let menuItems: any = [{
                            label: 'Remove Category',
                            handleClick: () => {handleRemove(targetGroup)}
                        }]
                        if (targetGroup.name === "Uncategorized") {
                            menuItems = null
                        } 
                        const addGroup: GroupCardsI = {
                            items: [],
                            title: targetGroup.name,
                            totalAmount: '0',
                            menuItems: menuItems,
                            _id: targetGroup._id
                        }
                        mappedGroups = [...mappedGroups, addGroup]
                    }
                }
            })

        }

        // last if the group is part of the default groups
        const defaultGroups = itemSubTypes.filter((group: SelectItemI) => group.name === "Uncategorized")

        defaultGroups.forEach((group: SelectItemI) => {
            const inGroups = mappedGroups.filter((mGroup: GroupCardsI) => mGroup.title === group.name)
            if (inGroups.length === 0) {
                const addGroup: GroupCardsI = {
                    items: [],
                    title: group.name,
                    totalAmount: '0',
                    _id: group._id
                }
                mappedGroups = [...mappedGroups, addGroup]
            }
        })
        updateActiveGroups(mappedGroups)

        // END OF ACTIVE GROUPS AND ITEMS
        
    }, [roomData, itemSubTypes])

    return {
        isLoading,
        groups,
        roomData,
        itemSubTypes,
        activeGroups
    }


}